import russelia from "../Product/Rusellia/Russelia.webp";
import russelia2 from "../Product/Rusellia/Russelia 2.webp";
import russelia3 from "../Product/Rusellia/Russelia 3.webp";
import russelia4 from "../Product/Rusellia/Russelia 4.webp";
import russelia5 from "../Product/Rusellia/Russelia 5.webp";
import russelia6 from "../Product/Rusellia/Russelia 6.webp";
import russelia7 from "../Product/Rusellia/Russelia 7 (1).webp";
import ravenia from "../Product/Ravenia/Ravenia.webp";
import ravenia2 from "../Product/Ravenia/18.webp";
import ravenia3 from "../Product/Ravenia/21.webp";
import ravenia4 from "../Product/Ravenia/22.webp";
import ravenia5 from "../Product/Ravenia/23.webp";
import ravenia6 from "../Product/Ravenia/25.webp";
import ravenia7 from "../Product/Ravenia/Screenshot_5.webp";
import dianella from "../Product/Dianella/Dianella.webp";
import dianella2 from "../Product/Dianella/Screenshot_1-5.webp";
import dianella3 from "../Product/Dianella/Screenshot_2-3.webp";
import dianella4 from "../Product/Dianella/Screenshot_3-1.webp";
import dianella5 from "../Product/Dianella/Screenshot_3-3.webp";
import dianella6 from "../Product/Dianella/Screenshot_4-2.webp";
import calliandra from "../Product/Calliandra/Calliandra.webp";
import calliandra2 from "../Product/Calliandra/Screenshot_1-2.webp";
import calliandra3 from "../Product/Calliandra/Screenshot_2.webp";
import calliandra4 from "../Product/Calliandra/Screenshot_3-1 (1).webp";
import calliandra5 from "../Product/Calliandra/Screenshot_4-2 (1).webp";
import calliandra6 from "../Product/Calliandra/Screenshot_5-3.webp";
import calliandra7 from "../Product/Calliandra/Screenshot_6-1.webp";
import celosia from "../Product/Celosia/Cellosia.webp";
import celosia2 from "../Product/Celosia/4.webp";
import celosia3 from "../Product/Celosia/6.webp";
import celosia4 from "../Product/Celosia/8.webp";
import celosia5 from "../Product/Celosia/9.webp";
import celosia6 from "../Product/Celosia/10.webp";
import celosia7 from "../Product/Celosia/11.webp";
import celosia8 from "../Product/Celosia/12.webp";
import celosia9 from "../Product/Celosia/Screenshot_6-2.webp";
import magnolia from "../Product/Magnolia/Magnolia (1).jpg";
import magnolia2 from "../Product/Magnolia/Magnolia (2).jpg";
import magnolia3 from "../Product/Magnolia/Magnolia (3).jpg";
import magnolia4 from "../Product/Magnolia/Magnolia (4).jpg";
import magnolia5 from "../Product/Magnolia/Magnolia (5).jpg";
import magnolia6 from "../Product/Magnolia/Magnolia (6).jpg";
import magnolia7 from "../Product/Magnolia/Magnolia (7).jpg";
import magnolia8 from "../Product/Magnolia/Magnolia (8).jpg";
import magnolia9 from "../Product/Magnolia/Magnolia (9).jpg";
import calamus from "../Product/Calamus/Calamus (4).jpeg";
import calamus2 from "../Product/Calamus/Calamus (2).jpeg";
import calamus3 from "../Product/Calamus/Calamus (3).jpeg";
import calamus4 from "../Product/Calamus/Calamus (5).jpeg";
import calamus7 from "../Product/Calamus/Calamus (8).jpeg";
import calamus8 from "../Product/Calamus/Calamus (9).jpeg";
import calamus9 from "../Product/Calamus/Calamus (10).jpeg";
import calamus10 from "../Product/Calamus/Calamus (11).jpeg";

const calamusImages = [
  calamus,
  calamus4,
  calamus7,
  calamus8,
  calamus9,
  calamus10,
  calamus2,
  calamus3,
];
const russeliaImages = [
  russelia,
  russelia2,
  russelia3,
  russelia4,
  russelia5,
  russelia6,
  russelia7,
];
const raveniaImages = [
  ravenia,
  ravenia2,
  ravenia3,
  ravenia4,
  ravenia5,
  ravenia6,
  ravenia7,
];
const dianellaImages = [
  dianella,
  dianella2,
  dianella3,
  dianella4,
  dianella6,
  dianella5,
];
const calliandraImages = [
  calliandra,
  calliandra2,
  calliandra3,
  calliandra4,
  calliandra5,
  calliandra6,
  calliandra7,
];
const celosiaImages = [
  celosia,
  celosia2,
  celosia3,
  celosia4,
  celosia5,
  celosia6,
  celosia7,
  celosia8,
  celosia9,
];
const magnoliaImages = [
  magnolia2,
  magnolia,
  magnolia3,
  magnolia4,
  magnolia5,
  magnolia6,
  magnolia7,
  magnolia8,
  magnolia9,
];

export {
  russeliaImages,
  raveniaImages,
  dianellaImages,
  calliandraImages,
  celosiaImages,
  magnoliaImages,
  calamusImages,
};
